import consumer from "./consumer"

/*
A basic event dispatch queue for AgreementChannel messages.

Here is an example of how to listen for an AgreementChannel, do:

```
import AgreementChannel from "./channels/agreement_channel"

// if you have a specific jid to listen for, pass it as the second argument
// When the message comes, your listener will be removed automatically
AgreementChannel.listen(agreementIdentifier, jid, (payload) => {
})

// Pass null to jid and you will receive all messages for the agreementIdentifier
AgreementChannel.listen(agreementIdentifier, null, (payload) => {
})

```

*/
class Listeners {
  constructor() {
    this.listeners = []
  }
  listen(agreementIdentifier, jid, callback) {
    this.listeners.push({
      agreementIdentifier,
      jid,
      callback,
    })
  }
  handle(payload, agreementIdentifier, jid) {
    const listenerIndex = this.listeners.findIndex((listener) => {
      if (agreementIdentifier !== listener.agreementIdentifier) {
        return false
      }
      // either the listener did not specify a jid or the jid must match
      return !listener.jid || jid === listener.jid
    })
    if (listenerIndex < 0) {
      return false
    }

    const listener = this.listeners[listenerIndex]
    listener.callback(payload)

    if (listener.jid) {
      this.listeners.splice(listenerIndex, 1)
    }

    return true
  }
}

const listeners = new Listeners()
export default listeners

$(()=>{
  let current_app = $('current_app#current_app_reference_uuid')
  if (current_app[0] == undefined) { return }

  let app_uuid = current_app.text().trim()
  // console.log("connecting to agreement channel for app uuid: ", app_uuid)

  consumer.subscriptions.create({ channel: "AgreementChannel", app_uuid: app_uuid }, {
    received(data) {
      this.handle_incoming_message(data)
    },

    handle_incoming_message(data) {
      // console.log("Incoming message on the agreement channel:")
      // console.log(data)

      let agreement_identifier = data['agreement_identifier']
      let agreement_url = data['agreement_url']
      let jid = data['jid']

      // let any registered listeners handle the message first
      // fall back to the default behavior if no listeners handle it
      // TODO - update this to only use the dynamic listener registration
      if (!listeners.handle(agreement_url, agreement_identifier, jid)) {
        // TODO - we may need to implement some kind of enforcement on agreement identifier
        // naming to ensure this works properly for all implementations
        if (agreement_identifier == 'quote_proposal' || agreement_identifier == 'quote_proposal_and_documents_to_sign') {
          let button_text = agreement_identifier == 'quote_proposal' ? 'Download Quote Proposal' : 'Quote & Application Packet'
          $('#download_quote_proposal_button').attr('href', agreement_url)
          $('#download_quote_proposal_button span').text(button_text)
          $('#download_quote_proposal_button').removeClass('loading')
          $('#email_subproducer_button').removeClass('loading')
          $('#email_subproducer_button').removeClass('disabled')
          if($("#bind_button").length > 0) {
            $('#bind_button').removeClass('loading')
          }
          if($("#bind_endorsement_button").length > 0) {
            $('#bind_endorsement_button').removeClass('loading')
          }
        } else if (agreement_identifier == 'indication_letter') {
          $('.download_indication_letter_button').attr('href', agreement_url)
          $('.download_indication_letter_button span').text('Download Indication Letter')
          $('.download_indication_letter_button').removeClass('loading')
        } else if (agreement_identifier == 'policy_document') {

          $('#download_policy_docs_button').attr('href', agreement_url)
          $('#download_policy_docs_button span').text('Download Policy Document')
          $('#download_policy_docs_button').removeClass('loading')
          $('#policy_bind_message').text('Your policy has been bound!')
          $('#issued_container').removeClass('pending')
          window.setupConfetti()
        } else if (agreement_identifier == 'invoice_document') {
          $('#download_invoice_button').attr('href', agreement_url)
          $('#download_invoice_button span').text('Download Invoice')
          $('#download_invoice_button').removeClass('loading')
        }
      }
    }

  })
})

