import { Popover } from "bootstrap";

$(() => {
  function closeAnyOpenPopovers() {
    $('[data-bs-toggle="popover"], [aria-describedby]').each(function () {
      var popoverInstance = Popover.getInstance(this);
      if (popoverInstance) {
        popoverInstance.hide();
      }
    });
  }

  function setupBlockDisplays() {
    if ($("pre.block_display").length == 0) {
      return;
    }

    function replaceHoverStringsInHtml(
      codeHtml,
      hoverString,
      hoverStringMap,
      popoverContentMap,
      index,
    ) {
      var popoverContent = "";
      var colors = [];
      Object.keys(hoverStringMap).forEach(function (key) {
        var normalized = Prism.plugins.NormalizeWhitespace.normalize(
          `  ${key}`,
          {
            "remove-trailing": true,
            "remove-indent": true,
            "left-trim": true,
          },
        );
        var highlighted = Prism.highlight(
          normalized,
          Prism.languages.ruby,
          "ruby",
        );
        popoverContent += `<div>In versions: ${hoverStringMap[key].versions.join(", ")}<br><pre class="inside_block_popover language-ruby"><code class="language-ruby">${highlighted}</code></pre></div>`;
        colors.push(hoverStringMap[key].checksum.substring(0, 6));
      });
      let escapedText = hoverString.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      let regex = new RegExp(escapedText, "g");
      let uniqueClass =
        "hover_string_" + index + "_" + Math.floor(Math.random() * 100000);

      var replacement = `<span class="block_hover_string dependency_underline ${uniqueClass}" style="text-decoration-color: #${colors[0]}">${hoverString}</span>`;
      popoverContentMap[uniqueClass] = popoverContent;
      codeHtml = codeHtml.replace(regex, replacement);
      return codeHtml;
    }

    $("pre.block_display").each(function () {
      let $codeBlock = $(this);
      let hoverStrings = $codeBlock.data("block_hover_strings");
      let hoverData = $codeBlock.data("block_hover_string_map");

      if (hoverStrings == undefined) {
        return;
      }

      var codeHtml = $codeBlock.html();
      var popoverContentMap = {};
      hoverStrings.forEach(function (hoverString, index) {
        let hoverStringMap = hoverData[hoverString];
        codeHtml = replaceHoverStringsInHtml(
          codeHtml,
          hoverString,
          hoverStringMap,
          popoverContentMap,
          index,
        );
      });
      $codeBlock.html(codeHtml);

      Object.entries(popoverContentMap).forEach(function (entry) {
        var span = $("." + entry[0]);
        span.popover({
          content: entry[1],
          html: true,
          trigger: "click",
          placement: "top",
          template:
            '<div class="popover block_display_hover_popover" role="tooltip">' +
            '<div class="popover-arrow"></div>' +
            '<h3 class="popover-header"></h3>' +
            '<div class="popover-body"></div>' +
            "</div>",
        });
      });
    });

    $(document).keydown(function (e) {
      if (e.key === "Escape") {
        closeAnyOpenPopovers();
      }
    });
  }
  setTimeout(setupBlockDisplays, 1000);

  function setupVersionTabs() {
    if ($("a.version_tab_link").length == 0) {
      // no versions
      return;
    }

    $("a.version_tab_link").on("click", (e) => {
      closeAnyOpenPopovers();
      let $clicked = $(e.target);
      let $tab = $clicked.closest(".version_tab_link");
      let $container = $clicked.closest(".with_version_tabs");

      let target_checksum = $tab.data("checksum");
      let color = $tab.data("color");
      let contrast_color = $tab.data("contrast_color");

      $container.find(".versioned_content").each(function () {
        if ($(this).data("checksum") == target_checksum) {
          $(this).removeClass("hidden");
        } else {
          $(this).addClass("hidden");
        }
      });

      $container.find(".version_tab_link").each(function () {
        if ($(this).data("checksum") == target_checksum) {
          $(this).find(".detail_version_tab").addClass("current_version_tab");
        } else {
          $(this)
            .find(".detail_version_tab")
            .removeClass("current_version_tab");
        }
      });

      $container.css({
        "border-color": color,
      });
      $container.find(".detail_block").css({
        "border-color": color,
      });
    });
  }
  setupVersionTabs();
});
