import Helpers from "../apply/helpers";

class UnderwriterReviews {
  static handle_add_subjectivities(e) {
    var $clone =  $('#subjectivity-form-row-template')
      .clone()

    $clone.toggleClass('hidden')
      .prop('id', 'subjectivity-form-row')
      .appendTo('#subjectivities')

    $clone.find('input').prop('required',true)
  }

  static handle_remove_subjectivities(e) {
    $(e.target).closest('#subjectivity-form-row').remove()
  }

  static toggle_waiting_on_broker(event) {
    const url = event.target.dataset['toggleUrl']

    Helpers.post_form_data({
      url: url,
      form_data: "",
      success: (res) => {}, // don't need to do anything
      failure: (res) => { event.target.checked = !event.target.checked } // Undo the toggle on the UI
    })
  }
}

$(() => {
  $(document).on("change", "#outcome", function(event) {
    if ($(this).val() === 'failed') {
      $('#failure_message_group').show();
      $('#message_to_broker').attr('required', true);
    } else {
      $('#failure_message_group').hide();
      $('#message_to_broker').removeAttr('required');
    }
  })
})

$(() => {
  $(document).on("click", ".waiting_on_broker_switch", UnderwriterReviews.toggle_waiting_on_broker);
})

$(() => {
  $(document).on("change", "underwriter_task_reviews_filter_form_select", function(event) {
    $(this).closest('form').trigger("submit");
  });
})

$(() => {
  $(document).on("change", "#send_email_attachment_field", function(event) {
    var totalSize = 0;
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
        totalSize += files[i].size;
    }

    if ( (totalSize / 1024 / 1024) > 20) {
      alert('Attachments are over 20MB. Please reduce file size and try again');
      event.target.value = '';
      return false;
    }
    return true;
  });
})


$(document).on('click', '#header_wrapper .add_button_wrapper', UnderwriterReviews.handle_add_subjectivities)
$(document).on('click', '#subjectivities .remove_button', UnderwriterReviews.handle_remove_subjectivities)