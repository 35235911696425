$(()=>{
  function restartApplication(e) {
    e.preventDefault()

    if (confirm("Are you sure you want to restart this application?")) {
      const clicked_element = $(e.target);
      clicked_element.addClass('loading');
      clicked_element.addClass('disabled');

      $.ajax({
        url: e.target.href,
        type: 'POST',
        dataType: "JSON",
        data: {},
        processData: false,
        contentType: false,
        success: (res) => {
          // console.log(res)
          if (res['success']) {
            window.location.replace(res['application_url'])
          } else {
            console.error(res)
            alert("Failed to restart this application. Please contact the Blitz tech team if issue persists.")
          }
        },
        error: (res) => {
          console.error(res)
          alert("Failed to restart this application. Please contact the Blitz tech team if issue persists.")
        }
      })
    }
  }

  $(document).on('click', '#restart_application_link', restartApplication)
  $(document).on('click', '#reopen_application_link', restartApplication)
})