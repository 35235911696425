$(()=>{

  // TODO - create a modal component to remove the need for these janky submit helpers
  $('#update_company_modal_submit').on('click', ()=>{
    if ($('#distribution_company_form')[0].checkValidity()) {
      $('#distribution_company_form').trigger('submit')
    } else {
      $('#distribution_company_form')[0].reportValidity()
    }
  })

  $('#update_subproducer_info_submit').on('click', ()=>{
    if ($('#subproducer_info_form')[0].checkValidity()) {
      $('#subproducer_info_form').trigger('submit')
    } else {
      $('#subproducer_info_form')[0].reportValidity()
    }
  })

  $('#company_assignments_submit').on('click', ()=>{
    if ($('#company_assignments_form')[0].checkValidity()) {
      $('#company_assignments_form').trigger('submit')
    } else {
      $('#company_assignments_form')[0].reportValidity()
    }
  })

  $('#new_company_modal_submit').on('click', ()=>{

    if ($('#distribution_company_form')[0].checkValidity()) {
      $('#distribution_company_form').trigger('submit')
    } else {
      $('#distribution_company_form')[0].reportValidity()
    }
  })

  $('.update_office_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let office_id = $clicked.data('office_id')

    if ($(`#update_office_${office_id}_form`)[0].checkValidity()) {
      $(`#update_office_${office_id}_form`).trigger('submit')
    } else {
      $(`#update_office_${office_id}_form`)[0].reportValidity()
    }
  })

  $('.update_producer_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let producer_id = $clicked.data('producer_id')

    if ($(`#update_producer_${producer_id}_form`)[0].checkValidity()) {
      $(`#update_producer_${producer_id}_form`).trigger('submit')
    } else {
      $(`#update_producer_${producer_id}_form`)[0].reportValidity()
    }

  })

  $('.update_notification_settings_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let producer_id = $clicked.data('producer_id')

    if ($(`#update_notification_settings_${producer_id}_form`)[0].checkValidity()) {
      $(`#update_notification_settings_${producer_id}_form`).trigger('submit')
    } else {
      $(`#update_notification_settings_${producer_id}_form`)[0].reportValidity()
    }
  })

  $('.update_permission_settings_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let producer_id = $clicked.data('producer_id')

    if ($(`#update_permission_settings_${producer_id}_form`)[0].checkValidity()) {
      $(`#update_permission_settings_${producer_id}_form`).trigger('submit')
    } else {
      $(`#update_permission_settings_${producer_id}_form`)[0].reportValidity()
    }
  })

  $('.update_license_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let license_id = $clicked.data('license_id')

    if ($(`#update_license_${license_id}_form`)[0].checkValidity()) {
      $(`#update_license_${license_id}_form`).trigger('submit')
    } else {
      $(`#update_license_${license_id}_form`)[0].reportValidity()
    }
  })

  $('.update_agency_license_modal_submit').on('click', (e)=> {

    let $clicked = $(e.target)
    let license_id = $clicked.data('license_id')

    if ($(`#update_agency_license_${license_id}_form`)[0].checkValidity()) {
      $(`#update_agency_license_${license_id}_form`).trigger('submit')
    } else {
      $(`#update_agency_license_${license_id}_form`)[0].reportValidity()
    }
  })

  $('#new_office_modal_submit').on('click', ()=> {

    if ($('#new_office_form')[0].checkValidity()) {
      $('#new_office_form').trigger('submit')
    } else {
      $('#new_office_form')[0].reportValidity()
    }
  })

  $('#new_license_modal_submit').on('click', ()=> {

    if ($('#new_license_form')[0].checkValidity()) {
      $('#new_license_form').trigger('submit')
    } else {
      $('#new_license_form')[0].reportValidity()
    }
  })

  $('#new_agency_license_modal_submit').on('click', ()=> {

    if ($('#new_agency_license_form')[0].checkValidity()) {
      $('#new_agency_license_form').trigger('submit')
    } else {
      $('#new_agency_license_form')[0].reportValidity()
    }
  })

  $(document).on('click', '#select_all_license_states', function(e) {
    // Get all the checkboxes inside the modal's internal .license_input_table and check them
    $(".license_input_table [type=checkbox]").not(':hidden').prop('checked', true)
  })

  $(document).on('click', '#deselect_all_license_states', function(e) {
    // Get all the checkboxes inside the modal's internal .license_input_table and un-check them
    $(".license_input_table [type=checkbox]").not(':hidden').prop('checked', false)
  })

  $('#new_producer_modal_submit').on('click', ()=>{

    if ($('#new_producer_form')[0].checkValidity()) {
      $('#new_producer_form').trigger('submit')
    } else {
      $('#new_producer_form')[0].reportValidity()
    }
  })

  $('#subproducer_csv_modal_submit').on('click', ()=>{
    console.log('clicked button')
    $('#subproducer_csv_form').trigger('submit')
  })

  $('#producer_csv_modal_submit').on('click', ()=>{
    console.log('clicked button')
    $('#producer_csv_form').trigger('submit')
  })

  $('#new_payment_plan_modal_submit').on('click', ()=>{

    if ($('#new_payment_plan_form')[0].checkValidity()) {
      $('#new_payment_plan_form').trigger('submit')
    } else {
      $('#new_payment_plan_form')[0].reportValidity()
    }
  })

  $('#new_commission_schedule_modal_submit').on('click', ()=>{

    if ($('#new_commission_schedule_form')[0].checkValidity()) {
      $('#new_commission_schedule_form').trigger('submit')
    } else {
      $('#new_commission_schedule_form')[0].reportValidity()
    }
  })

  $('.update_commission_rate_modal_submit').on('click', (e)=> {
    let $clicked = $(e.target)
    let commission_schedule_uuid = $clicked.data('commission_schedule_uuid')

    if ($(`#update_commission_rate_${commission_schedule_uuid}_form`)[0].checkValidity()) {
      $(`#update_commission_rate_${commission_schedule_uuid}_form`).trigger('submit')
    } else {
      $(`#update_commission_rate_${commission_schedule_uuid}_form`)[0].reportValidity()
    }
  })

  $('#update_billing_contact_submit').on('click', ()=>{
    let $form = $("#submit_billing_contact_form");
    if ($form[0].checkValidity()) {
      $form.trigger('submit')
    } else {
      $form[0].reportValidity()
    }
  })
})

$(document).ready(function() {
  $('#show_deactivated_licenses_button').on('click', function() {
    $('.deactivated_nipr_license').removeClass('hidden');
    $('#show_deactivated_licenses_button').addClass('hidden');
  });
});