$(document).ready(function() {
  $('#open_esign_modal').on('click', function() {
    const $modal = $('#esign_status_modal');
    const rectOpen = $(this)[0].getBoundingClientRect();
    if ($modal.is(':visible')) {
      $modal.fadeOut(0);
      $('#open_esign_modal').css({
        border: "",
      });
    } else {
      $modal.css({
        top: rectOpen.top + 'px',
        position: 'absolute'
      });
      $modal.fadeIn(0);

      $('#open_esign_modal').css({
        border: "1px solid rgba(255, 99, 21)",
        borderRadius: "10px"
      });
    }
  });
});


$(document).on('click', '#distribution_esign_reminder', function(e) {
  e.preventDefault();

  var form = $('#distribution_esign_reminder_form');

  $.ajax({
    url: form.attr('action'),
    type: form.attr('method'),
    data: form.serialize(),
    success: function(response) {
      if (response['success'] == false)
      {
        alert(response['error_message']);
      }
      else
      {
        $("#distribution_checkmark_icon_reminder").removeClass("hidden");
        $("#distribution_checkmark_icon_reminder").addClass("distribution_esign_reminder_button_img");
        $("#distribution_envelope_icon_reminder").addClass("hidden");
        alert("Reminder Sent");
      }
    },
    error: function(xhr, status, error) {
      alert("Reminder Failed To Send");
    }
  });
});

$(document).ready(function() {
  $('#onboarding_step_two_form').on('submit', function() {
    if (this.checkValidity())
      {
        $(this).find('button[type="submit"]').addClass('loading').prop('disabled', true);
      }
  });

  $('#onboarding_edit_esign_button').on('click', function() {
    $(this).hide();
    $('#onboarding_edit_esign_status_form').removeClass('hidden');
  });
});