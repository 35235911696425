$(() => {

  if ($('#admin_applications_grid_assigned_to_me').is(':checked')) {
    $('#admin_applications_grid_admin_assigned').val([]).trigger('change');
    $('#admin_applications_grid_admin_assigned_select_and_label').addClass('hidden');
    $('#admin_applications_grid_primary_bd').val([]).trigger('change');
    $('#admin_applications_grid_primary_bd_select_and_label').addClass('hidden');
  }

  // only do this setup if the date range picker is present
  if($("#effective_date_range_application").length) {
    var start = null;
    var start_value = $('#effective_date_start').val();
    if(start_value != ''){
      start = moment(start_value);
    }
    else {
      start = moment().Today;
    }

    var end = null;
    var end_value = $('#effective_date_end').val();
    if(end_value != ''){
      end = moment(end_value);
    }
    else {
      end = moment().Today;
    }

    $("#effective_date_range_application").daterangepicker({
      startDate: start,
      endDate: end,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD',
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Next 7 Days': [moment().add(6, 'days'), moment()],
        'Next 30 Days': [moment().add(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')]
    }
    });

    if(start && end) {
      $('#effective_date_range_application').val(start_value + ' - ' + end_value);
    }
    else {
      $('#effective_date_range_application').val('');
    }

    $('#effective_date_range_application').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD') + ' - ' + picker.endDate.format('YYYY-MM-DD'));

      $('#effective_date_start').val(picker.startDate.format('YYYY-MM-DD'))
      $('#effective_date_end').val(picker.endDate.format('YYYY-MM-DD'))
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#effective_date_range_application').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
      $('#effective_date_start').val(null)
      $('#effective_date_end').val(null)

      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_program_identifier').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_journey_identifier').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_company_id').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_producer_uuid').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_application_type').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_admin_assigned').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_primary_bd').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );
    });

    $('#admin_applications_grid_assigned_to_me').on('change', function(ev, picker) {
      $("#new_admin_applications_grid_admin_applications_grid").trigger( "submit" );

      if ($('#admin_applications_grid_assigned_to_me').is(':checked')) {
        $('#admin_applications_grid_admin_assigned').val([]).trigger('change');
        $('#admin_applications_grid_admin_assigned_select_and_label').addClass('hidden');
        $('#admin_applications_grid_primary_bd').val([]).trigger('change');
        $('#admin_applications_grid_primary_bd_select_and_label').addClass('hidden');
      }

      if ($('#admin_applications_grid_assigned_to_me').not(':checked')) {
        $('#admin_applications_grid_admin_assigned_select_and_label').removeClass('hidden');
        $('#admin_applications_grid_primary_bd_select_and_label').removeClass('hidden');
      }

    });

  }

  $(document).on('click', '#edit_producer_button', (event)=>{
    setEditProducerFormVisibility(true)
    setEditSupportProducerFormVisibility(false)
  })

  $(document).on('click', '#edit_producer_cancel', (event)=>{
    setEditProducerFormVisibility(false)
  })

  $(document).on('click', '#edit_support_producer_button', (event)=>{
    setEditProducerFormVisibility(false)
    setEditSupportProducerFormVisibility(true)
  })

  $(document).on('click', '#edit_support_producer_cancel', (event)=>{
    setEditSupportProducerFormVisibility(false)
  })

  $(document).on('click', '#toggle_application_test_flag', (event)=>{
    toggleApplicationTestFlag(event)
  })

  function setEditProducerFormVisibility(visibile) {
    if(visibile) {
      let $edit_button_container = $('#edit_producer_container');
      $edit_button_container.hide();

      let $form = $('#edit_producer_form')
      $form.show();
    } else {
      let $edit_button_container = $('#edit_producer_container');
      $edit_button_container.show();

      let $form = $('#edit_producer_form')
      $form.hide();
    }
  }

  function setEditSupportProducerFormVisibility(visibile) {
    if(visibile) {
      let $edit_button_container = $('#edit_support_producer_container');
      $edit_button_container.hide();

      let $form = $('#edit_support_producer_form')
      $form.show();
    } else {
      let $edit_button_container = $('#edit_support_producer_container');
      $edit_button_container.show();

      let $form = $('#edit_support_producer_form')
      $form.hide();
    }
  }

  function toggleApplicationTestFlag(event) {
    $target = $(event.target)
    const url = $target.data('toggle-url')
    let form_data = new FormData()

    // the property is already changed
    form_data.append('current_value', !$target.prop('checked'))
    Helpers.post_form_data({
      url: url,
      form_data: form_data,
      success: (res) => {
        $target.prop('checked', res.new_value)
      },
      failure: (res) => {
        // recover value
        $target.prop('checked', !$target.prop('checked'))
      }
    })

  }

})
